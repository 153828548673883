import { firestore } from "@/firebase"
import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"

import { FirestoreClass } from "./FirestoreClass"

export type WorkTime = ({ start: { hour: number, minute: number }, end: { hour: number, minute: number } } | null)

export class ApiData {
    name: string = ""
    // NUTZERDATEN
    consumerKey: string = ""
    consumerSecret: string = ""
    sandboxConsumerSecret: string = ""
    sandboxUserEmail: string = ""
    sandboxUserName: string = ""
    sandboxUserPassword: string = ""
    // AUTHENTIFIZIERUNGSPROZESS
    sandboxOauthToken: string = ""
    sandboxOauthTokenSecret: string = ""
    sandboxOauthTokenVerifier: string = ""
    oauthToken: string = ""
    oauthTokenSecret: string = ""
    oauthTokenVerifier: string = ""

    constructor(data: Record<string, unknown>) {
        this.name = data?.name as string || ""

        this.consumerKey = data?.consumerKey as string || ""
        this.consumerSecret = data?.consumerSecret as string || ""
        this.sandboxConsumerSecret = data?.sandboxConsumerSecret as string || ""
        this.sandboxUserEmail = data?.sandboxUserEmail as string || ""
        this.sandboxUserName = data?.sandboxUserName as string || ""
        this.sandboxUserPassword = data?.sandboxUserPassword as string || ""

        this.sandboxOauthToken = data?.sandboxOauthToken as string || ""
        this.sandboxOauthTokenSecret = data?.sandboxOauthTokenSecret as string || ""
        this.sandboxOauthTokenVerifier = data?.sandboxOauthTokenVerifier as string || ""
        this.oauthToken = data?.oauthToken as string || ""
        this.oauthTokenSecret = data?.oauthTokenSecret as string || ""
        this.oauthTokenVerifier = data?.oauthTokenVerifier as string || ""
    }

    toData() {
        const data: Record<string, unknown> = {
            name: this.name,

            consumerKey: this.consumerKey,
            consumerSecret: this.consumerSecret,
            sandboxConsumerSecret: this.sandboxConsumerSecret,
            sandboxUserEmail: this.sandboxUserEmail,
            sandboxUserName: this.sandboxUserName,
            sandboxUserPassword: this.sandboxUserPassword,

            sandboxOauthToken: this.sandboxOauthToken,
            sandboxOauthTokenSecret: this.sandboxOauthTokenSecret,
            sandboxOauthTokenVerifier: this.sandboxOauthTokenVerifier,
            oauthToken: this.oauthToken,
            oauthTokenSecret: this.oauthTokenSecret,
            oauthTokenVerifier: this.oauthTokenVerifier
        }
        return data
    }
}

export class User extends FirestoreClass {
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    picture: string | null = null
    useTK: boolean = false
    internalnumber:string = ""
    externalnumber:string = ""
    replacenamesTK: boolean = false
    apiDataImmoscout: ApiData = new ApiData({})

    constructor(id: string) {
        super(id)
    }

    override fromData(data: Record<string, unknown>): void {
        this.firstName = data.firstName as string || ""
        this.lastName = data.lastName as string || ""
        this.email = data.email as string || ""
        this.useTK = data.useTK as boolean || false
        this.internalnumber = data.internalnumber as string || ""
        this.externalnumber = data.externalnumber as string || ""
        this.replacenamesTK = data.replacenamesTK as boolean || false
        this.apiDataImmoscout = new ApiData(data.apiDataImmoscout as Record<string, unknown>)

        if (data.picture) {
            this.picture = data.picture as string
        }
    }

    toData(){
        const data: Record<string, unknown> = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            picture: this.picture,
            useTK: this.useTK,
            internalnumber: this.internalnumber,
            externalnumber: this.externalnumber,
            replacenamesTK: this.replacenamesTK,
            apiDataImmoscout: this.apiDataImmoscout.toData()
        }
        return data
    }
    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(firestore, "user")
    }
}