import { PublicUser } from "@/classes/PublicUser"
import { firestore } from "@/firebase"
import { collection, doc, getDoc } from "firebase/firestore"
import { Module } from "vuex"

export interface PublicUserState {
    users: Map<string, PublicUser>
}

const module: Module<PublicUserState, unknown> = {
    state: {
        users: new Map()
    },
    actions: {
        getUserInfo({ state }, userID: string) {
            return new Promise<PublicUser>(async (resolve, reject) => {
                if (state.users.has(userID)) {
                    resolve(state.users.get(userID)!)
                    return
                }
                const ref = collection(firestore, "public_user")
                const user = await getDoc(doc(ref, userID))
                if (user.exists()) {
                    const publicUser = new PublicUser(user.id)
                    publicUser.fromData(user.data()!)
                    state.users.set(userID, publicUser)
                    resolve(state.users.get(user.id)!)
                } else {
                    reject(new Error("user_not_found"))
                }
            })
        }
    }
}
export default module