import { Interested } from "@/classes/Interested"
import { collection, query, where, getDocs, Unsubscribe } from "firebase/firestore"
import { Module } from "vuex"
import store from ".."

export interface InterestedState {
    isLoading: boolean
    interested: Interested[]
    firebaseListener: {
        interested: Unsubscribe | null
    }
}

const module: Module<InterestedState, unknown> = {
    state: {
        isLoading: false,
        interested: [],
        firebaseListener: {
            interested: null
        }
    },
    actions: {
        getInterested({ state }, id: string) {
            return new Promise(async (resolve, reject) => {
                for (const interested of state.interested) {
                    if (interested.id == id) {
                        resolve(interested)
                        return
                    }
                }
                const company = store.state.company!.company!
                const _i = new Interested(company, id)
                if (await _i.load()) {
                    state.interested.push(_i)
                    resolve(_i)
                    return
                }
                reject(new Error("not_found"))
            })
        },
        searchInterested({ state }, search: string) {
            return new Promise(async (resolve, reject) => {
                if (!search) {
                    reject(new Error("no_search_term"))
                    return
                }
                const company = store.state.company!.company!
                const searchTerms = search.toUpperCase().split(" ")
                const collectionRef = collection(company.ref, "interested")
                const queryRef = query(
                    collectionRef,
                    where("dispatcher", "==", store.state.auth!.profile!.company),
                    where("_searchIndex", "array-contains-any", searchTerms)
                )
                const searchResults = await getDocs(queryRef)
                const interested: Interested[] = []
                for (const result of searchResults.docs) {
                    const _i = new Interested(company, result.id)
                    _i.fromData(result.data())
                    const interestedIndex = state.interested.findIndex((a) => a.id == _i.id)
                    if (interestedIndex == -1) {
                        state.interested.push(_i)
                    }
                    interested.push(_i)
                }
                resolve(interested)
            })
        }
    }
}
export default module