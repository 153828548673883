import { Snackbar } from "@/interfaces/SnackbarInterface"
import { Module } from "vuex"

export interface AppState {
    theme: "florian-thoms" | "business-luchs" | "custom",
    snackbar: {
        visible: boolean,
        color: string,
        message: string
    }
}

const module: Module<AppState, unknown> = {
    state: {
        theme: "florian-thoms",
        snackbar: {
            visible: false,
            color: "default",
            message: ""
        }
    },
    mutations: {
        snackbar(state, { message, color }: Snackbar) {
            state.snackbar.message = message
            if (color) {
                state.snackbar.color = color
            } else {
                state.snackbar.color = "default"
            }
            state.snackbar.visible = true
        }
    }
}
export default module