
import { useStore } from "vuex"
import { computed } from "@vue/reactivity"
import { useRouter } from "vue-router"
import { defineComponent } from "vue"
import { auth } from "@/firebase"
import { signOut } from "firebase/auth"
import { mdiLogoutVariant, mdiCog } from "@mdi/js"

import IconItem from "@/components/theme/icon-item.vue"
import AvatarItem from "@/components/theme/avatar-item.vue"
import LogoItem from "../theme/logo-item.vue"

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()

        const logout = async () => {
            await signOut(auth)
            router.push("/login").catch()
        }
        const openRoute = (url: string) => {
            router.push(url).catch()
        }

        const isSignedIn = computed(() => {
            return store.state.auth.isSignedIn
        })

        const profilePicture = computed(() => {
            if (!store.state.auth.isSignedIn) {
                return null
            }
            if (!store.state.auth.profile) {
                return null
            }
            return store.state.auth.profile.picture
        })

        return {
            logout,
            openRoute,
            isSignedIn,
            profilePicture,
            mdiLogoutVariant,
            mdiCog
        }
    },
    components: {
        IconItem,
        AvatarItem,
        LogoItem
    }
})
