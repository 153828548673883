import { firestore } from "@/firebase"
import { collection, CollectionReference, doc, DocumentData, DocumentReference } from "firebase/firestore"
import { Address } from "./Company"
import { FirestoreClass } from "./FirestoreClass"

export type WorkTime = ({ start: { hour: number, minute: number }, end: { hour: number, minute: number } } | null)
export class PublicUser extends FirestoreClass {
    firstName: string = ""
    lastName: string = ""
    email: string = ""
    picture: string | null = null
    workTime: WorkTime[] = []
    workAddresses: (Address | null)[] = []

    constructor(id: string) {
        super(id)
    }
    override fromData(data: Record<string, unknown>): void {
        this.firstName = data.firstName as string
        this.lastName = data.lastName as string
        this.email = data.email as string
        if (data.picture) {
            this.picture = data.picture as string
        }
        if (data.workTime) {
            this.workTime = data.workTime as WorkTime[]
        } else {
            this.workTime = [
                null,
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } },
                { start: { hour: 9, minute: 0 }, end: { hour: 18, minute: 0 } }
            ]
        }
        if (data.workAddresses) {
            this.workAddresses = []
            for (const address of data.workAddresses as (Record<string, unknown> | null)[]) {
                if (address) {
                    this.workAddresses.push(new Address(address))
                } else {
                    this.workAddresses.push(null)
                }
            }
        } else {
            this.workAddresses = [
                null,
                null,
                null,
                null,
                null,
                null,
                null
            ]
        }
    }
    toData(){
        const data: Record<string, unknown> = {
            
        }
        if (this.workTime) {
            data.workTime = this.workTime
        }
        if (this.workAddresses) {
            const workAddresses = []
            for (const address of this.workAddresses) {
                if (address) {
                    workAddresses.push(address.toData())
                } else {
                    workAddresses.push(null)
                }
            }
            data.workAddresses = workAddresses
        }
        return data
    }
    override get ref(): DocumentReference<DocumentData> {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef(): CollectionReference<DocumentData> {
        return collection(firestore, "public_user")
    }
}